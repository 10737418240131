<template>
  <div v-if="isCanDo">
    <v-form ref="customForm" :lazy-validation="lazy">
      <v-row no-gutters justify="center" align="center">
        <v-card class="pa-6 mt-2" width="90%">
          <v-row no-gutters justify="center" align="center">
            <v-img
              max-height="120px"
              max-width="120px"
              src="../assets/logo_survey.png"
            ></v-img>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <h3 class="mt-10 headtitle text-center">แบบประเมินสภาพชุมชน</h3>
          </v-row>
          <v-row no-gutters justify="center" align="center">
            <h3 class="headtitle text-center">{{ village }}</h3>
          </v-row>
        </v-card>

        <v-row md="12" no-gutters justify="center" align="center">
          <!-- <v-card class="pa-6 mt-2" width="90%" style="background-color: #c0c0c0"> -->
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">เพศ</h3>
            <v-radio-group v-model="question2" :rules="rules.required">
              <v-radio label="ชาย" value="ชาย"> </v-radio>
              <v-radio label="หญิง" value="หญิง"> </v-radio>
              <v-radio label="ไม่ระบุ" value="ไม่ระบุ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">อายุ</h3>
            <v-radio-group v-model="question3" :rules="rules.required">
              <v-radio label="1-20 ปี" value="1-20 ปี"> </v-radio>
              <v-radio label="21-30 ปี" value="21-30 ปี"> </v-radio>
              <v-radio label="31-40 ปี" value="31-40 ปี"> </v-radio>
              <v-radio label="41-50 ปี" value="41-50 ปี"> </v-radio>
              <v-radio label="51-60 ปี" value="51-60 ปี"> </v-radio>
              <v-radio label="61 ปีขึ้นไป" value="61 ปีขึ้นไป"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">ระดับการศึกษา</h3>
            <v-radio-group v-model="question4" :rules="rules.required">
              <v-radio
                label="ประถมศึกษา (ป1-ป6)"
                value="ประถมศึกษา (ป1-ป6)"
              ></v-radio>
              <v-radio
                label="มัธยมศึกษาตอนต้น (ม1-ม3)"
                value="มัธยมศึกษาตอนต้น (ม1-ม3)"
              >
              </v-radio>
              <v-radio
                label="มัธยมศึกษาตอนปลาย (ม4-ม6)"
                value="มัธยมศึกษาตอนปลาย (ม4-ม6)"
              >
              </v-radio>
              <v-radio label="ปริญญาตรี" value="ปริญญาตรี"> </v-radio>
              <v-radio label="สูงกว่าปริญญาตรี" value="สูงกว่าปริญญาตรี">
              </v-radio>
              <v-radio label="ไม่ได้เรียนหนังสือ" value="ไม่ได้เรียนหนังสือ">
              </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">ลักษณะที่พักอาศัยปัจจุบัน</h3>
            <v-radio-group v-model="question5" :rules="rules.required">
              <v-radio label="บ้านตนเอง" value="บ้านตนเอง"> </v-radio>
              <v-radio label="บ้านเช่า" value="บ้านเช่า"> </v-radio>
              <v-radio label="อาศัยอยู่กับญาติ" value="อาศัยอยู่กับญาติ">
              </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              ท่านอาศัยอยู่ในชุมชน/หมู่บ้านนี้มาเป็นเวลาเท่าใด
            </h3>
            <v-radio-group v-model="question6" :rules="rules.required">
              <v-radio label="ไม่ถึง 1 ปี" value="ไม่ถึง 1 ปี"> </v-radio>
              <v-radio label="1 - 5 ปี" value="1 - 5 ปี"> </v-radio>
              <v-radio label="6 - 10 ปี" value="6 - 10 ปี"> </v-radio>
              <v-radio label="10  ปี ขึ้นไป" value="10  ปี ขึ้นไป"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">ปัจจุบันท่านประกอบอาชีพอะไร</h3>
            <v-radio-group v-model="question7" :rules="rules.required">
              <v-radio label="ว่างงาน" value="ว่างงาน"> </v-radio>
              <v-radio label="นักเรียน/นักศึกษา" value="นักเรียน/นักศึกษา">
              </v-radio>
              <v-radio
                label="ค้าขาย/ประกอบธุรกิจส่วนตัว"
                value="ค้าขาย/ประกอบธุรกิจส่วนตัว"
              >
              </v-radio>
              <v-radio
                label="ข้าราชการ/พนักงานรัฐวิสาหกิจ"
                value="ข้าราชการ/พนักงานรัฐวิสาหกิจ"
              >
              </v-radio>
              <v-radio label="ลูกจ้างบริษัท" value="ลูกจ้างบริษัท"> </v-radio>
              <v-radio label="เกษตรกร" value="เกษตรกร"> </v-radio>
              <v-radio label="อื่น ๆ" value="มี"></v-radio>
              <div v-if="question7 === 'มี'">
                <v-text-field
                  outlined
                  dense
                  v-model="question7other"
                  label="โปรดระบุ"
                ></v-text-field>
              </div>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              ท่านอาศัยอยู่ในชุมชน/หมูบ้านนี้มีความสุขมากน้อยเพียงใด
            </h3>
            <v-radio-group v-model="question8" :rules="rules.required">
              <v-radio
                label="มีความสุขมาก มีความปลอดภัย"
                value="มีความสุขมาก มีความปลอดภัย"
              >
              </v-radio>
              <v-radio label="มีความสุข ตามอัตภาพ" value="มีความสุข ตามอัตภาพ">
              </v-radio>
              <v-radio
                label="ไม่มีค่อยมีความสุข รู้สึกไม่ปลอดภัย"
                value="ไม่มีค่อยมีความสุข รู้สึกไม่ปลอดภัย"
              >
              </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีการขายยาเสพติดหรือไม่
            </h3>
            <v-radio-group v-model="question9_1" :rules="rules.required">
              <v-radio label="มี" value="มี"> </v-radio>
              <div v-if="question9_1 == 'มี'">
                <v-text-field
                  v-model="question9_1des"
                  label="โปรดระบุรายละเอียด(ถ้ามี)"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-radio label="ไม่มี" value="ไม่มี"> </v-radio>
              <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้เสพยาเสพติดหรือไม่
            </h3>
            <v-radio-group v-model="question9_2" :rules="rules.required">
              <v-radio label="มี" value="มี"> </v-radio>
              <div v-if="question9_2 == 'มี'">
                <v-text-field
                  v-model="question9_2des"
                  label="โปรดระบุรายละเอียด(ถ้ามี)"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-radio label="ไม่มี" value="ไม่มี"> </v-radio>
              <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีปัญหาที่เกี่ยวข้องกับยาเสพติด เช่น ผู้สมคบ ผู้สนับสนุน
              ผู้ให้ความช่วยเหลือหรือไม่
            </h3>
            <v-radio-group v-model="question9_3" :rules="rules.required">
              <v-radio label="มี" value="มี"> </v-radio>
              <div v-if="question9_3 == 'มี'">
                <v-text-field
                  v-model="question9_3des"
                  label="โปรดระบุรายละเอียด(ถ้ามี)"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-radio label="ไม่มี" value="ไม่มี"> </v-radio>
              <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีผู้ครอบครอง
              อาวุธปืนเถือน/วัตถุระเบิด/อาวุธสงครามหรือไม่
            </h3>
            <v-radio-group v-model="question9_4" :rules="rules.required">
              <v-radio label="มี" value="มี"> </v-radio>
              <div v-if="question9_4 == 'มี'">
                <v-text-field
                  v-model="question9_4des"
                  label="โปรดระบุรายละเอียด(ถ้ามี)"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-radio label="ไม่มี" value="ไม่มี"> </v-radio>
              <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้มีพฤติกรรมชอบเล่นการพนัน/ขายหวยออนใต้ดินหรือไม่
            </h3>
            <v-radio-group v-model="question9_5" :rules="rules.required">
              <v-radio label="มี" value="มี"> </v-radio>
              <div v-if="question9_5 == 'มี'">
                <v-text-field
                  v-model="question9_5des"
                  label="โปรดระบุรายละเอียด(ถ้ามี)"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-radio label="ไม่มี" value="ไม่มี"> </v-radio>
              <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้ที่มีพฤติกรรมลักลอบตัดไม้หวงห้าม/ไม่มีค่าหรือไม่
            </h3>
            <v-radio-group v-model="question9_6" :rules="rules.required">
              <v-radio label="มี" value="มี"> </v-radio>
              <div v-if="question9_6 == 'มี'">
                <v-text-field
                  v-model="question9_6des"
                  label="โปรดระบุรายละเอียด(ถ้ามี)"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-radio label="ไม่มี" value="ไม่มี"> </v-radio>
              <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้ที่มีพฤติกรรมบุกรุกที่สาธารณะ/ทำลายทรัพยากรธรรมชาติหรือไม่
            </h3>
            <v-radio-group v-model="question9_7" :rules="rules.required">
              <v-radio label="มี" value="มี"> </v-radio>
              <div v-if="question9_7 == 'มี'">
                <v-text-field
                  v-model="question9_7des"
                  label="โปรดระบุรายละเอียด(ถ้ามี)"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-radio label="ไม่มี" value="ไม่มี"> </v-radio>
              <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้ที่มีพฤติกรรมลักลอบจำหน่ายสินค้าหนีภาษีหรือไม่
            </h3>
            <v-radio-group v-model="question9_8" :rules="rules.required">
              <v-radio label="มี" value="มี"> </v-radio>
              <div v-if="question9_8 == 'มี'">
                <v-text-field
                  v-model="question9_8des"
                  label="โปรดระบุรายละเอียด(ถ้ามี)"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-radio label="ไม่มี" value="ไม่มี"> </v-radio>
              <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้ที่มีพฤติกรรมออกเงินกู้นอกระบบหรือไม่
            </h3>
            <v-radio-group v-model="question9_9" :rules="rules.required">
              <v-radio label="มี" value="มี"> </v-radio>
              <div v-if="question9_9 == 'มี'">
                <v-text-field
                  v-model="question9_9des"
                  label="โปรดระบุรายละเอียด(ถ้ามี)"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-radio label="ไม่มี" value="ไม่มี"> </v-radio>
              <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้ที่มีพฤติกรรมเป็นผู้มีอิทธิพลหรือไม่
            </h3>

            <v-radio-group v-model="question9_10" :rules="rules.required">
              <v-radio label="มี" value="มี"> </v-radio>
              <div v-if="question9_10 == 'มี'">
                <v-text-field
                  v-model="question9_10des"
                  label="โปรดระบุรายละเอียด(ถ้ามี)"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-radio label="ไม่มี" value="ไม่มี"> </v-radio>
              <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีผู้ที่มีพฤติกรรมการขับรถแว้น คึกคะนอง เสียงดัง
              หรือก่อความรำคานหรือไม่
            </h3>
            <v-radio-group v-model="question9_11" :rules="rules.required">
              <v-radio label="มี" value="มี"> </v-radio>
              <div v-if="question9_11 == 'มี'">
                <v-text-field
                  v-model="question9_11des"
                  label="โปรดระบุรายละเอียด(ถ้ามี)"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-radio label="ไม่มี" value="ไม่มี"> </v-radio>
              <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีที่มีพฤติกรรมลักเล็กขโมยน้อยหรือไม่
            </h3>
            <v-radio-group v-model="question9_12" :rules="rules.required">
              <v-radio label="มี" value="มี"> </v-radio>
              <div v-if="question9_12 == 'มี'">
                <v-text-field
                  v-model="question9_12des"
                  label="โปรดระบุรายละเอียด(ถ้ามี)"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-radio label="ไม่มี" value="ไม่มี"> </v-radio>
              <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่
              มีที่มีพฤติกรรมจับกลุ่มดื่มสุรามั่วสุม/ชอบนำพวกทะเลาะวิวาทหรือไม่
            </h3>
            <v-radio-group v-model="question9_13" :rules="rules.required">
              <v-radio label="มี" value="มี"> </v-radio>
              <div v-if="question9_13 == 'มี'">
                <v-text-field
                  v-model="question9_13des"
                  label="โปรดระบุรายละเอียด(ถ้ามี)"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-radio label="ไม่มี" value="ไม่มี"> </v-radio>
              <v-radio label="ไม่แน่ใจ" value="ไม่แน่ใจ"> </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">ปัญหาอื่นๆ โปรดระบุ</h3>
            <v-textarea
              v-model="question9_14"
              outlined
              dense
              rows="3"
            ></v-textarea>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              ท่านคิดว่าสถานการณ์การแผ่ระบาดยาเสพติดในชุมชนท่านอยู่ระดับใด
            </h3>
            <v-radio-group v-model="question10" :rules="rules.required">
              <v-radio
                label="รุนแรง (มีทั้งผู้เสพ/ผู้ขายหลายรายอยู่ในชุมชน)"
                value="รุนแรง (มีทั้งผู้เสพ/ผู้ขายหลายรายอยู่ในชุมชน)"
              >
              </v-radio>
              <v-radio
                label="ปานกลาง (มีทั้งจำนวนผู้เสพ/ผู้ขาย อยู่ในชุมชน)"
                value="ปานกลาง (มีทั้งจำนวนผู้เสพ/ผู้ขาย อยู่ในชุมชน)"
              >
              </v-radio>
              <v-radio
                label="เบาบาง (มีผู้เสพในชุมชน แต่ ผู้ขาย มาจากที่อื่น)"
                value="เบาบาง (มีผู้เสพในชุมชน แต่ ผู้ขาย มาจากที่อื่น)"
              >
              </v-radio>
            </v-radio-group>
          </v-card>
          <v-card class="px-6 mt-2 pt-2" width="90%">
            <h3 class="headtitle1">
              ท่านคิดว่าปัญหายาเสพติดจะลดลงหรือหมดไปจากชุมชนอย่างยั่งยืนได้อย่างไร
            </h3>
            <v-textarea
              v-model="question11"
              outlined
              dense
              rows="3"
            ></v-textarea>
          </v-card>
        </v-row>

        <v-card class="pa-6 mt-2 text-center" width="90%">
          <v-btn
            color="#833133"
            style="color: white"
            @click="submit()"
            class="mt-2"
            >ส่งข้อมูล</v-btn
          >
        </v-card>
      </v-row>
    </v-form>

    <div>
      <v-row no-gutters justify="center" align="center">
        <br />
        <v-col cols="12" class="text-center mt-4">
          <p style="color: #fff; font-size: 0.8rem;">
            วันที่ทำแบบสอบถาม: {{ convertDate() }}
          </p>
          <p style="color: #fff; font-size: 0.8rem;">UID: {{ uid }}</p>
        </v-col>
      </v-row>
    </div>
  </div>
  <div v-else>
    <v-row no-gutters justify="center" align="center">
      <v-card height="400px" width="620px" class="mt-2">
        <v-row no-gutters justify="center" align="center" class="mt-10">
          <v-img
            max-height="100px"
            max-width="100px"
            src="../assets/logo_survey.png"
          ></v-img>
          <v-col cols="12"
            ><h2 class="text-center mt-10 headtitle">
              ขออภัย ท่านทำแบบสอบถามไปแล้ว
            </h2></v-col
          >
          <v-col cols="12" class="text-center mt-4">
            ขอบคุณที่ร่วมตอบแบบสอบถามและประเมินความพึงพอใจในครั้งนี้
          </v-col>
          <v-col cols="11" class="text-center">
            ทางเราจะปรับปรุงและพัฒนาโครงการให้ดียิ่งขึ้น
          </v-col>
        </v-row>
        <v-row no-gutters justify="center" align="center" class="mt-4"
          ><v-col cols="12" class="text-center">
            <b>ท่านต้องการแจ้งข่าวสารหรือไม่ ?</b>
          </v-col>
          <v-btn @click="goToForm2()" class="mt-2 ml-2"
            >แจ้งข่าวสาร</v-btn
          ></v-row
        >
      </v-card>
    </v-row>

    <div>
      <v-row no-gutters justify="center" align="center">
        <br />
        <v-col cols="12" class="text-center mt-4">
          <p style="color: #fff; font-size: 0.8rem;">
            วันที่ทำแบบสอบถาม: {{ convertDate() }}
          </p>
          <p style="color: #fff; font-size: 0.8rem;">UID: {{ uid }}</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Encode, Decode } from "@/services";
export default {
  data() {
    return {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
      question6: "",
      question7: "",
      question7other: "",
      question8: "",
      question9_1: "",
      question9_2: "",
      question9_3: "",
      question9_4: "",
      question9_5: "",
      question9_6: "",
      question9_7: "",
      question9_8: "",
      question9_9: "",
      question9_10: "",
      question9_11: "",
      question9_12: "",
      question9_13: "",
      question9_14: "",
      question9_1des: "",
      question9_2des: "",
      question9_3des: "",
      question9_4des: "",
      question9_5des: "",
      question9_6des: "",
      question9_7des: "",
      question9_8des: "",
      question9_9des: "",
      question9_10des: "",
      question9_11des: "",
      question9_12des: "",
      question9_13des: "",
      question10: "",
      question11: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      lazy: false,
      isCanDo: true,
      formId: 9999,
      uid: "",
      // qrCodeId: 0,
    };
  },
  created() {
    this.getURL();
  },
  methods: {
    convertDate() {
      return moment().format("MM/DD/YYYY hh:mm");
    },
    async getURL() {
      const url = this.$router.currentRoute.query;
      // const response = await this.axios.get(
      //   `${process.env.VUE_APP_API}/customform?station=xx` +
      //     url.station
      // );
      console.log("url", url);
      this.village = url.village;
      this.question1 = url.station;
      // this.qrCodeId = url.qrCodeId;
      // var navigator_info = window.navigator;
      // var screen_info = window.screen;
      // var uid = navigator_info.mimeTypes.length;
      // uid += navigator_info.userAgent.replace(/\D+/g, "");
      // uid += navigator_info.plugins.length;
      // uid += screen_info.height || "";
      // uid += screen_info.width || "";
      // uid += screen_info.pixelDepth || "";
      // console.log("uid", uid);
      // this.uid = uid;
      var navigator_info = window.navigator;
      var screen_info = window.screen;
      var result = [];
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 10; i++) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        );
      }

      var genString = result.join("");

      if (Object.prototype.hasOwnProperty.call(localStorage, "policeSurvey")) {
        var checkPeoplepollSurvey = JSON.parse(
          Decode.decode(localStorage.getItem("policeSurvey"))
        ).uid;
        if (checkPeoplepollSurvey !== "") {
          console.log(checkPeoplepollSurvey);
          this.uid = checkPeoplepollSurvey;
        } else {
          var uid = navigator_info.mimeTypes.length;
          uid += navigator_info.userAgent.replace(/\D+/g, "");
          uid += navigator_info.plugins.length;
          uid += screen_info.height || "";
          uid += screen_info.width || "";
          uid += screen_info.pixelDepth || "";
           uid += moment().valueOf();
        uid += genString;
          console.log("uid", uid);
          localStorage.setItem("policeSurvey", Encode.encode({ uid }));
          this.uid = uid;
        }
      } else {
        var uid = navigator_info.mimeTypes.length;
        uid += navigator_info.userAgent.replace(/\D+/g, "");
        uid += navigator_info.plugins.length;
        uid += screen_info.height || "";
        uid += screen_info.width || "";
        uid += screen_info.pixelDepth || "";
        uid += moment().valueOf();
        uid += genString;
        console.log("uid", uid);
        localStorage.setItem("policeSurvey", Encode.encode({ uid }));
        this.uid = uid;
      }

      // const qrResponse = await this.axios.get(
      //   `${process.env.VUE_APP_API}/form/getQrCode/` +
      //     this.qrCodeId
      //   // "http://localhost:8081/form/getQrCode/" +
      //   //   this.qrCodeId
      // );
      // console.log("check log response", qrResponse);
      const datachecklog = {
        deviceId: this.uid,
        formId: this.formId,
        station: this.question1,
      };
      console.log("datachecklog", datachecklog);
      // const response = await this.axios.post(
      //   `${process.env.VUE_APP_API}/assessCommunity/checkLog`,
      //   datachecklog
      // );
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/assessCommunity/checkLog`,
        datachecklog
      );
      console.log("check log response", response.data);
      if (response.data.message === "TRUE") {
        this.isCanDo = true;
        // await this.getURL();
      } else if (response.data.data) {
        if (response.data.data.status == "ยังไม่ทำ") this.isCanDo = true;
        // await this.getURL();
      } else {
        this.isCanDo = false;
      }
    },
    async submit() {
      if (this.$refs.customForm.validate(true)) {
        if (this.question7 == "มี") {
          this.question7 = this.question7other;
        }
        if (this.question9_1 == "มี") {
          this.question9_1 = this.question9_1des;
        }
        if (this.question9_2 == "มี") {
          this.question9_2 = this.question9_2des;
        }
        if (this.question9_3 == "มี") {
          this.question9_3 = this.question9_3des;
        }
        if (this.question9_4 == "มี") {
          this.question9_4 = this.question9_4des;
        }
        if (this.question9_5 == "มี") {
          this.question9_5 = this.question9_5des;
        }
        if (this.question9_6 == "มี") {
          this.question9_6 = this.question9_6des;
        }
        if (this.question9_7 == "มี") {
          this.question9_7 = this.question9_7des;
        }
        if (this.question9_8 == "มี") {
          this.question9_8 = this.question9_8des;
        }
        if (this.question9_9 == "มี") {
          this.question9_9 = this.question9_9des;
        }
        if (this.question9_10 == "มี") {
          this.question9_10 = this.question9_10des;
        }
        if (this.question9_11 == "มี") {
          this.question9_11 = this.question9_11des;
        }
        if (this.question9_12 == "มี") {
          this.question9_12 = this.question9_12des;
        }
        if (this.question9_13 == "มี") {
          this.question9_13 = this.question9_13des;
        }

        const data = {
          villageName: this.question1,
          gender: this.question2,
          age: this.question3,
          education: this.question4,
          currentHousing: this.question5,
          liveTime: this.question6,
          career: this.question7,
          happyLevel: this.question8,
          drugSales: this.question9_1,
          takenDrugs: this.question9_2,
          contributor: this.question9_3,
          possessWeapon: this.question9_4,
          gambling: this.question9_5,
          cutWood: this.question9_6,
          destroyNatural: this.question9_7,
          smuggledGoods: this.question9_8,
          informalLoan: this.question9_9,
          influencer: this.question9_10,
          noisyDriving: this.question9_11,
          stealing: this.question9_12,
          mingle: this.question9_13,
          otherProblems: this.question9_14,
          drugEpidemic: this.question10,
          reducedDrugProblem: this.question11,
          deviceId: this.uid,
          station: this.question1,
        };
        console.log("loggg", data);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/assessCommunity/create`,
          data
        );
        // const response = await this.axios.post(
        //   `https://policesurveyservice.yuzudigital.com/assessCommunity/create`,
        //   data
        // );
        console.log("response", response);
        // console.log("response", response.data.data.response_status);
        if (response.data.response_status === "SUCCESS") {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "success",
            text: "บันทึกข้อมูลสำเร็จ",
          });
          location.reload();
        } else {
          console.log("ไม่เข้าif");
        }
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "warning",
          text: "กรุณากรอกข้อมูลให้ครบ",
        });
      }
    },
    goToForm2() {
      // this.$router.push("form2");
      window.open("form2");
    },
  },
};
</script>

<style scoped>
.headtitle1 {
  font-weight: 800;
  color: #833133;
}
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
